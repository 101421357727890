import DatePickerIcon from '@mui/icons-material/CalendarToday';
import KeyBoardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyBoardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import styles from './manage-users.module.scss';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useState } from 'react';

type Props = {
  filterList: any[];
  index: number;
  column: any;
  onChange: any;
};

// eslint-disable-next-line
const DatePickerFilter = ({ filterList, index, column, onChange }: Props) => {
  const [filterEnabled, setFilterEnabled] = useState(filterList[index][0] && filterList[index][0] != '');
  
  const initialValue = filterList[index][0];
  let isUnlimited = !initialValue || initialValue === 'unlimited' || initialValue === '';
  const limitDate = isUnlimited ? '1970/01/01' : initialValue;
  const limitDateParsed = limitDate.replaceAll('/', '-');//we display dates with / but luxon wants - to parse the date
  const datePickerTextFieldVisibility = isUnlimited ? 'hidden' : 'visible';

  const toggleFilter = event => {
    setFilterEnabled(event.target.checked);
    if (filterEnabled){
      // eslint-disable-next-line
      filterList[index][0] = '';
    }
    else {
      // eslint-disable-next-line
      filterList[index][0] = 'unlimited';
    }
    onChange(filterList[index], index, column);
  };

  return (
    <>
      <FormGroup>
        <FormControlLabel control={<Checkbox checked={filterEnabled} onChange={toggleFilter} />} label='Access expired'/>
      </FormGroup>
      
      <div className={styles.manage_user__dateContainer}>
        <DatePicker
          disabled={!filterEnabled}
          slots={{
            openPickerIcon: DatePickerIcon,
            leftArrowIcon: KeyBoardArrowLeft,
            rightArrowIcon: KeyBoardArrowRight,
          }}
          onChange={date => {
            const parsedDate = date.toFormat('yyyy/MM/dd');
            //eslint-disable-next-line
            filterList[index][0] = parsedDate;
            onChange(filterList[index], index, column);
            if (parsedDate !== '1970/01/01'){
              isUnlimited = false;
            }
          }}
          value={DateTime.fromISO(limitDateParsed)}
          maxDate={DateTime.local().minus({ days: 1 })}
          minDate={DateTime.fromISO('1970-01-01')}
          format='yyyy/MM/dd'
          timezone='Europe/Paris'
          className='user-authority-date-picker'
          slotProps={{
            textField: {
              sx: {
                '& .MuiInputBase-input.Mui-disabled': {
                  class: styles.manage_user__datePickerInputFieldDisabled,
                  visibility: datePickerTextFieldVisibility,
                },
                '.MuiOutlinedInput-input': { 
                  padding:'4px 0 4px 0',
                  visibility: datePickerTextFieldVisibility,
                },
                boxShadow: 'none',
                '.MuiOutlinedInput-notchedOutline': { border: 0 },
              },
            },
          }} />
      </div>

      { isUnlimited && <div className={styles.override_date_text_field}>Unlimited</div>}
    </>
  );
};

export default DatePickerFilter;