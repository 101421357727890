
import { CircularProgress, Tooltip } from '@mui/material';
import Loader from 'components/loader/loader';
import CustomDatePicker from 'components/user/custom-date-picker';
import CustomToolbar from 'components/user/custom-toolbar';
import MachineTemplateSelector from 'components/user/machine-template-selector';
import { Row, canEditStatusOrDeleteAccess, isConnectedUserManager, parseFilterData, parseRowData, mapAccessMgtDtoToRowArray } from 'components/user/manage-users-utils';
import PermissionSelector from 'components/user/permission-selector';
import { allPermissionAuthorities } from 'constants/user-authority';
import { useDebouncedValue } from 'hooks/useDebouncedValue';
import { DateTime } from 'luxon';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { formatDateFromMillis, formatDateTimeFromMillis } from 'services/date-service';
import userService from 'services/user-service';
import vmInstanceService from 'services/vm-instance-service';
import { useAppDispatch } from 'store';
import { addMachineTemplates } from 'store/actions/machine-template-actions';
import { RootState } from 'store/reducers';
import { getSiteAvailableMachineTemplatesFromState } from 'store/selectors/machine-template-selectors';
import { AccessMgtDataDTO, FilterAclObject, RightsManagementTableState, SortObject, UpdateAccessDTO } from "types";
import styles from './manage-users.module.scss';
import ResourcePathSelector from './resource-path-selector';
import UserDeleteButton from './user-delete-button';
import DatePickerFilter from './date-picker-filter';

const mapState = (state: RootState) => ({
  siteList: state.resourcesReducer.siteList,
  loggedUserId: state.loggedUserReducer.id,
  getSiteAvailableMachineTemplatesFromState: (siteId: number) => getSiteAvailableMachineTemplatesFromState(state, siteId),
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

const initialTableState: RightsManagementTableState = {
  page: 0,
  rowsPerPage: 25,
  sortOrder: {
    name: 'createdDate', //default sort order name must exist in backend
    direction: 'desc',
  },
  filterList: {
    resourcePath: '',
    permissionAuthority: '',
    expired: '',
    expireSoon: false,
  },
  searchText: '',
};

export const ManageUsers = ({ siteList, loggedUserId, getSiteAvailableMachineTemplatesFromState }: ReduxProps) => {
  const location = useLocation();
  const resourcePath = getResourcePathFromQueryParams(location);
  const expire = getExpireFromQueryParams(location);
  const [localData, setLocalData] = useState<Row[]>([]);
  const [isReady, setReady] = React.useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [tableState, setTableState] = useState<RightsManagementTableState>({
    ...initialTableState,
    filterList: {
      ...initialTableState.filterList,
      resourcePath: resourcePath ?? '',
      expireSoon: !!expire,
      expired: expire,
    },
    searchText: getFullNameFromQueryParams(location),
  });
  const [searchText, setSearchText] = useState<string>('');
  const debouncedSearchTerm = useDebouncedValue(searchText, 300);
  const [pendingUpdates, setPendingUpdates] = useState<number[]>([]);

  const dispatch = useAppDispatch();

  function getResourcePathFromQueryParams(location) {
    const searchParams = new URLSearchParams(location?.search);
    const resourcePath = searchParams.get('path') ?? '';
    return resourcePath !== 'undefined' ? decodeURIComponent(resourcePath) : null;
  }

  function getExpireFromQueryParams(location) {
    const searchParams = new URLSearchParams(location?.search);
    const expirationDate = searchParams.get('expire') ?? '';
    return expirationDate !== 'undefined' ? decodeURIComponent(expirationDate) : '';
  }


  function getFullNameFromQueryParams(location) {
    const params = new URLSearchParams(location?.search);
    const firstName = params.get('firstName') ?? '';
    const lastName = params.get('lastName') ?? '';
    return `${decodeURIComponent(firstName)} ${decodeURIComponent(lastName)}`.trim();
  }


  useEffect(() => {
    (async () => {
      if (!isReady) {
        forceRefresh();
      }
    })();
  }, []);

  useEffect(() => {
    changePage(tableState.page, tableState.rowsPerPage, tableState.sortOrder, tableState.searchText, tableState.filterList);
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const siteIds = new Set(localData.map(row => row.siteId))
    siteIds.forEach(siteId => {
      const availableMachineTemplates = getSiteAvailableMachineTemplatesFromState(siteId);
      if (!availableMachineTemplates) {
        vmInstanceService.getMachineTemplates(siteId).then((machineTemplates) => {
          const result = { [siteId]: machineTemplates };
          dispatch(addMachineTemplates(result));
        });
      }
    });
  }, [localData]);

  const updateState = (rows: AccessMgtDataDTO) => {
    setCount(rows.totalElements);
    setPage(rows.currentPage);
  };

  const endOfReload = () => {
    setReady(true);
  };

  const changePage = (newPage: number, rowsPerPage: number, sortOrder: SortObject, searchText: string, filter: FilterAclObject) => {
    setPendingUpdates([]);
    userService.getUserListToManage(newPage, rowsPerPage, searchText, sortOrder, filter).then(usersData => {
      updateState(usersData);
      setLocalData(mapAccessMgtDtoToRowArray(usersData));
      setPendingUpdates([]);
    })
      .finally(endOfReload);
  };

  const forceRefresh = () => {
    changePage(tableState.page, tableState.rowsPerPage, tableState.sortOrder, tableState.searchText, tableState.filterList);
  };

  const getUpdateBody = (rowData: Row): UpdateAccessDTO => {
    return {
      authority: rowData.permissionAuthority,
      machineTemplateId: rowData.machineTemplateId,
      expirationDate: DateTime.fromISO(rowData.expirationDate).toFormat('dd/LL/yyyy'),
    };
  };

  const updateUser = (rowData: Row) => {
    setPendingUpdates(pendingUpdates.concat(rowData.aclId));
    const body = getUpdateBody(rowData);
    return userService.updateUserAccessControl(rowData.aclId, body).then(forceRefresh).catch(forceRefresh).finally(endOfReload);
  };

  //The names in headCells are used in the backend for sorting so they should not be changed
  //For example we want to sort by site label so the column name must be "siteLabel" which is read as "site.label" by the backend
  //Case is important too, "userLastName" is not correct but "userLastname" is
  const headCells = [
    {
      name: 'aclId',
      options: {
        display: 'excluded',
        filter: false,
        sort: false,
      },
    },
    {
      name: 'userId',
      numeric: true,
      label: 'Id',
      options: {
        setCellProps: () => {
          return {
            className: styles.manage_user__userId,
          };
        },
        sort: true,
        filter: false,
      },
    },
    {
      name: 'siteId',
      options: {
        display: 'excluded',
        filter: false,
        options: {
          sort: false,
        },
      },
    },
    {
      name: 'userFirstname',
      numeric: false,
      label: 'FirstName',
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: 'userLastname',
      numeric: false,
      label: 'LastName',
      options: {
        sort: true,
        filter: false,
      },
    },
    {
      name: 'resourcePath',
      label: 'Path (Site/Zone/Project)',
      options: {
        sort: true,
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <ResourcePathSelector
                filterList={filterList}
                index={index}
                column={column}
                onChange={onChange}
              />
            );
          },
        },
        filterList: tableState.filterList.resourcePath ? [tableState.filterList.resourcePath] : [],
      },
    },
    {
      name: 'permissionAuthority',
      numeric: false,
      label: 'Status', //FIXME should we rename this column ? status -> permission / authority 
      options: {
        sort: true,
        filterType: 'dropdown',
        filterOptions: {
          names: allPermissionAuthorities,
        },
        customBodyRender: (value, data) => {
          const row: Row = parseRowData(data.rowData);
          const canEdit = canEditStatusOrDeleteAccess(row, siteList, loggedUserId);
          const isPending = pendingUpdates.includes(row.aclId);
          return <PermissionSelector localData={localData} updateUser={updateUser} value={value} isPending={isPending} canEdit={canEdit} data={data} row={row} />;
        },
      },
    },
    {
      name: 'expirationDate',
      numeric: false,
      label: 'EndOfAccess',
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value, data) => {
          const row: Row = parseRowData(data.rowData);
          const isPending = pendingUpdates.includes(row.aclId);
          const months = process.env.REACT_APP_MAX_RIGHTS_EXPIRATION_TIME_MONTH ? +process.env.REACT_APP_MAX_RIGHTS_EXPIRATION_TIME_MONTH : 6;
          return (
            <CustomDatePicker localData={localData} updateUser={updateUser} value={value} isPending={isPending}
              data={data} row={row} months={months} isConnectedUserManager={isConnectedUserManager} siteList={siteList} />
          );
        },
      },
    },
    {
      name: 'isExpired', //Only for filter
      label: ' ',
      options: {
        sort: false,
        display: 'excluded',
        filterType: 'custom',
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <DatePickerFilter
                filterList={filterList}
                index={index}
                column={column}
                onChange={onChange}
              />
            );
          },
        },
        filterList: tableState.filterList.expired ? [tableState.filterList.expired] : [],
      },
    },
    {
      name: 'willExpireWithin30Days', //Only for filter
      label: ' ',
      options: {
        filterType: 'checkbox',
        filterOptions: {
          names: ['Access will expire within 30 days'],
        },
        filterList: tableState.filterList.expireSoon ? ['Access will expire within 30 days'] : [],
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'machineTemplateId',
      numeric: false,
      label: 'Machine category',
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value: number, data) => {
          const row: Row = parseRowData(data.rowData);
          const isPending = pendingUpdates.includes(row.aclId);
          return (
            <MachineTemplateSelector localData={localData} updateUser={updateUser} value={value} isPending={isPending}
              data={data} row={row} isConnectedUserManager={isConnectedUserManager} siteList={siteList} />
          );
        },
      },
    },
    {
      name: 'lastModifiedDate',
      numeric: false,
      label: 'LastUpdated',
      options: {
        sort: true,
        filter: false,
        customBodyRender: (value, data) => {
          const row: Row = parseRowData(data.rowData);
          return (
            <div className={styles.manage_user__dateContainer} >
              <Tooltip title={row.lastModifiedBy + ' on ' + formatDateTimeFromMillis(value)}>
                <div className={styles.manage_user__datePickerInputFieldLabel}> {value && formatDateFromMillis(value)}</div>
              </Tooltip>
            </div>
          );
        },
      },
    },
    {
      name: 'lastModifiedBy',
      options: {
        display: 'excluded',
        filter: false, sort: false,
      },
    },
    {
      name: '',
      label: '', // "Actions" like Delete...
      options: {
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false, // Cannot hide it !
        customBodyRender: (value, data) => {
          const row: Row = parseRowData(data.rowData);
          const canDelete = canEditStatusOrDeleteAccess(row, siteList, loggedUserId);
          return canDelete && <UserDeleteButton aclId={row.aclId} deleteCallBack={forceRefresh} />;
        },
      },
    },
  ];

  const options = {
    print: false,
    filter: true,
    serverSide: true,
    count: count,
    page: page,
    searchText: tableState.searchText,
    elevation: 0,
    rowsPerPageOptions: [10, 25, 50, 100],
    draggableColumns: {
      enabled: true,
    },
    selectableRows: 'none',
    sortOrder: tableState.sortOrder,
    filterList: tableState.filterList,
    rowsPerPage: tableState.rowsPerPage,
    customToolbar: () => {
      return <CustomToolbar refresh={forceRefresh} />;
    },
    onTableChange: (action, newTableState) => {
      switch (action) {
        case 'search':
          // Search option is handled differently to have a debounce and avoid calling backend multiple times
          setSearchText(newTableState.searchText);
          setTableState({
            rowsPerPage: newTableState.rowsPerPage,
            page: newTableState.page,
            sortOrder: newTableState.sortOrder,
            filterList: parseFilterData(newTableState.filterList),
            searchText: newTableState.searchText,
          });
          break;
        case 'changePage':
        case 'changeRowsPerPage':
        case 'filterChange':
        case 'sort':
        case 'resetFilters':
          changePage(newTableState.page, newTableState.rowsPerPage, newTableState.sortOrder, newTableState.searchText, parseFilterData(newTableState.filterList));
          setTableState({
            rowsPerPage: newTableState.rowsPerPage,
            page: newTableState.page,
            sortOrder: newTableState.sortOrder,
            filterList: parseFilterData(newTableState.filterList),
            searchText: newTableState.searchText,
          });
          break;
        default:
          break;
      }
    },
  };

  return (
    <div className={styles.manage_user__topContainer}>
      {
        isReady ? (
            <div className={styles.manage_user__tableContainer}>
              <MUIDataTable
                title={
                  <>
                    <span className={styles.manage_user__title}>Users rights</span>
                    {pendingUpdates.length > 0 && (
                        <CircularProgress
                          size={24}
                          style={{ marginLeft: 15, position: 'relative', top: 4 }} />
                    )}
                  </>
                }
                data={localData}
                columns={headCells}
                options={options} />
            </div>
        ) :
          (
            <div className={styles.manage_user__loadingSpinner}>
              <Loader />
            </div>
          )
      }
    </div>
  );
};

export default connector(ManageUsers);